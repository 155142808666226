import { navigate } from "gatsby"
import React from "react"
import { Container, Row, Button } from "react-bootstrap"
import { auth } from "../firebase"
import Layout from "../components/utils/layout"
import SEO from "../components/utils/seo"
import InputCreator from "../components/styledInputs/InputCreator"
import "../styles/SignUpPage.css"
import "../styles/createStudentAccountPage.css"
import HashLoader from "react-spinners/HashLoader"
import getUserData from "../scripts/getUserData"
import login from "../scripts/login"
import logout from "../scripts/logout"
import { default as firebase } from "../firebase"
import NewAsyncButton from "../components/utils/NewAsyncButton"
import dots from "../images/bgs/dots.svg"

class HandleEmailVerification extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      authenticated: null,
      process: "",
      mode: "",
      newPassword: "",
      actionCode: "",
      lang: "",
      continueUrl: "",
    }
    this.handleVerifyEmail = this.handleVerifyEmail.bind(this)
    this.handleRecoverEmail = this.handleRecoverEmail.bind(this)
    this.handleResetPassword = this.handleResetPassword.bind(this)
    this.getParameterByName = this.getParameterByName.bind(this)
    this.sendResetEmail = this.sendResetEmail.bind(this)
    this.handleForm = this.handleForm.bind(this)
    this.goToPortal = this.goToPortal.bind(this)
  }

  componentDidMount() {
    auth.onAuthStateChanged(user => {
      if (!user) {
        this.setState({ authenticated: false })
      } else {
        this.setState({ authenticated: true })
      }
      let mode = this.getParameterByName("mode")
      let actionCode = this.getParameterByName("oobCode")
      let continueUrl = this.getParameterByName("continueUrl")
      let lang = this.getParameterByName("lang") || "en"
      this.setState({
        mode: mode,
        actionCode: actionCode,
        continueUrl: continueUrl,
        lang: lang,
      })
      switch (mode) {
        case "resetPassword":
          this.setState({
            mode: "Reset Password",
            process: "Please enter your new password below.",
          })
          break
        case "recoverEmail":
          this.handleRecoverEmail(actionCode, lang)
          break
        case "verifyEmail":
          this.handleVerifyEmail(actionCode, continueUrl, lang)
          break
        default:
          navigate("/404/")
          break
      }
    })
  }

  getParameterByName(name) {
    name = name.replace(/[\[\]]/g, "\\$&")
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)")
    let results = regex.exec(window.location.href)
    if (!results) {
      return null
    } else if (!results[2]) {
      return ""
    }

    return decodeURIComponent(results[2].replace(/\+/g, " "))
  }

  goToPortal() {
    getUserData(auth.currentUser.uid)
      .then(data => {
        if (data.userType === "student") {
          navigate("/student-dashboard/")
        } else if (data.userType === "teacher") {
          navigate("/teacher-dashboard/")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  handleResetPassword() {
    this.setState({ process: "Saving..." })
    auth
      .verifyPasswordResetCode(this.state.actionCode)
      .then(email => {
        auth
          .confirmPasswordReset(this.state.actionCode, this.state.newPassword)
          .then(resp => {
            if (auth.currentUser) {
              logout()
                .then(() => {
                  login(email, this.state.newPassword)
                    .then(() => {
                      this.setState({ process: "Saved!" })
                    })
                    .catch(error => {
                      console.log(error)
                    })
                })
                .catch(error => {
                  console.log(error)
                })
            } else {
              login(email, this.state.newPassword)
                .then(() => {
                  this.setState({ process: "Saved!" })
                })
                .catch(error => {
                  console.log(error)
                })
            }
          })
          .catch(error => {
            console.log(error)
          })
      })
      .catch(error => {
        console.log(error)
      })
  }

  handleRecoverEmail(actionCode, lang) {}

  handleVerifyEmail(actionCode, continueUrl, lang) {
    this.setState({ process: "Verifying...", mode: "Verify Email" })
    auth
      .applyActionCode(actionCode)
      .then(resp => {
        if (auth.currentUser !== null && auth.currentUser !== undefined) {
          auth.currentUser
            .reload()
            .then(() => {
              auth.currentUser
                .getIdToken(true)
                .then(() => {
                  if (auth.currentUser && auth.currentUser.emailVerified) {
                    getUserData(auth.currentUser.uid)
                      .then(data => {
                        require("firebase/functions")
                        let sendWelcomeEmail = firebase
                          .functions()
                          .httpsCallable("callables-sendWelcomeEmail")

                        sendWelcomeEmail(data).catch(error => {
                          console.log(error)
                        })

                        let userType = data.userType
                        let nextPage = ""
                        if (userType === "student") {
                          if (data.joinedSlack) {
                            nextPage = "congratulations"
                          } else {
                            nextPage = "JoinSlack"
                            if (typeof window !== "undefined") {
                              if (window.fbq != null) {
                                window.fbq(
                                  "track",
                                  `Teacher Registration Complete`,
                                  { uid: auth.currentUser.uid }
                                )

                                window.fbq(
                                  "trackCustom",
                                  "Teacher Registration Complete Custom",
                                  { uid: auth.currentUser.uid }
                                )
                              }
                            }
                          }
                        } else if (userType === "teacher") {
                          nextPage = "teacherCongrats"
                        }
                        this.setState({ process: "Verified!" })
                        if (nextPage.length === 0) {
                          return
                        }

                        setTimeout(() => {
                          navigate("/" + nextPage + "/")
                        }, 700)
                      })
                      .catch(error => {
                        console.log(error)
                      })
                  } else {
                    this.setState({
                      process: "Error verifying email. Please try again.",
                    })
                  }
                })
                .catch(error => {
                  console.log(error)
                })
            })
            .catch(error => {
              console.log(error)
              this.setState({
                process: "Error verifying email. Please try again.",
              })
              //TODO Error handle
            })
        } else {
          this.setState({ process: "Verified!" })

          setTimeout(() => {
            navigate(continueUrl.substring(continueUrl.indexOf(".org") + 4))
          }, 700)
        }

        // Email address has been verified.

        // TODO: Display a confirmation message to the user.
        // You could also provide the user with a link back to the app.

        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
      })
      .catch(error => {
        console.log(error)
        this.setState({ process: "Error verifying email. Please try again." })
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
      })
  }

  sendResetEmail(reset) {
    let currentUser = auth.currentUser
    if (currentUser) {
      currentUser
        .sendEmailVerification({ url: this.state.continueUrl })
        .then(() => {
          reset("Success!")
        })
        .catch(error => {
          console.log(error)
          reset("An error occured. Try again?")
        })
    } else {
      navigate("/Login/")
    }
  }

  handleForm(event) {
    this.setState({ newPassword: event.target.value })
  }

  render() {
    if (this.state.mode === "Verify Email") {
      return (
        <Container className="topSpacing justify-content-center">
          <Row className="justify-content-center">
            <h3 class="noneText blackText">{this.state.process}</h3>
          </Row>

          <Row className="justify-content-center" style={{ marginTop: "50px" }}>
            {this.state.process === "Verifying..." ? (
              <HashLoader />
            ) : this.state.process === "Verified!" ? (
              <Button
                variant="contained"
                color="success"
                onClick={this.goToPortal}
              >
                Continue to Portal
              </Button>
            ) : (
              <NewAsyncButton
                buttonVariants={["dashed", "danger", "danger", "dashed"]}
                buttonTexts={[
                  "Resend Email",
                  "Resending...",
                  "Error Resending. Try Again?",
                  "Resent! Check your inbox & spam",
                ]}
                buttonColor={["", "", "", ""]}
                callback={this.sendResetEmail}
              />
            )}
          </Row>
        </Container>
      )
    } else if (this.state.mode === "Reset Password") {
      return (
        <Container className="topSpacing justify-content-center">
          <Row className="justify-content-center">
            <h3 class="noneText blackText">{this.state.process}</h3>
          </Row>

          <Row className="justify-content-center" style={{ marginTop: "30px" }}>
            {this.state.process === "Saving..." ? (
              <HashLoader />
            ) : this.state.process === "Saved!" ? (
              <Button
                variant="contained"
                color="success"
                onClick={this.goToPortal}
              >
                Continue to Portal
              </Button>
            ) : (
              <InputCreator
                titles={["New Password"]}
                errors={[false]}
                types={["password"]}
                names={["newPassword"]}
                struct={[1]}
                callback={this.handleForm}
                values={[this.state.newPassword]}
              />
            )}
          </Row>
          {this.state.process !== "Saving..." &&
            this.state.process !== "Saved!" && (
              <Row className="justify-content-center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleResetPassword}
                >
                  Save
                </Button>
              </Row>
            )}
        </Container>
      )
    } else if (this.state.mode === "Recover Email") {
      return (
        <Container className="topSpacing justify-content-center">
          <Row className="justify-content-center">
            <h3 class="noneText blackText">{this.state.process}</h3>
          </Row>

          <Row className="justify-content-center" style={{ marginTop: "50px" }}>
            {this.state.process === "Verifying..." ? (
              <HashLoader />
            ) : this.state.process === "Verified!" ? (
              <Button
                variant="contained"
                color="success"
                onClick={this.goToPortal}
              >
                Continue to Portal
              </Button>
            ) : (
              <NewAsyncButton
                buttonVariants={["dashed", "danger", "danger", "dashed"]}
                buttonTexts={[
                  "Resend Email",
                  "Resending...",
                  "Error Resending. Try Again?",
                  "Resent! Check your inbox & spam",
                ]}
                callback={this.sendResetEmail}
              />
            )}
          </Row>
        </Container>
      )
    } else {
      return null
    }
  }
}

const HandleVerification = () => (
  <Layout image={dots} full>
    <SEO title="Handle Email Verification" />
    <HandleEmailVerification />
  </Layout>
)
export default HandleVerification
